import { YuLifeLocale } from "@hooks";
import { ProductCardDimensions } from "./_types";
import { config } from "./config";

export * from "./_types";
export * from "./analytics";
export * from "./cols";
export * from "./config";
export * from "./cookies";
export * from "./debounce";
export * from "./isClientSide";
export * from "./isEmail";
export * from "./isAllowedEmail";
export * from "./isUrl";
export * from "./isIpAddress";
export * from "../styles";
export * from "./share";
export * from "./correctYuLifeUrls";
export * from "./locale";
export * from "./blog";
export * from "./meta";
export * from "./resources";

export const getPath = (path: string): string => {
  const isPathExternal = /^https?:\/\//i.test(path);
  return isPathExternal ? path : `/static${path}`;
};

export const file = (
  path: string,
  prefix = config.STATIC_FOLDER,
  version = config.CI_JOB_ID,
): string => `${prefix}${path}?v=${version}`;

export const createArray = (numberOfItems: number, fill?: any): any[] => {
  return new Array(numberOfItems).fill(fill || null);
};

export const roundToNearestHalf = (num: number): number => Math.round(num * 2) / 2;

export const addThousandSeparators = (num: number): string => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const smoothScrollTo = (
  window: Window,
  elementId: string,
  offset = 0,
  addWindowHeight = false,
  isSmooth = true,
): void => {
  if (window && window.scroll) {
    const { offsetTop } = document.getElementById(elementId) || { offsetTop: 0 };
    const additional = addWindowHeight ? window.innerHeight : 0;
    window.scroll({ top: offsetTop + offset + additional, behavior: isSmooth ? "smooth" : "auto" });
  }
};

// "case-studies" => "Case-studies"
export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((str) => str.charAt(0).toUpperCase() + str.substring(1))
    .join(" ");
};

// "case-studies" => "Case studies"
export const kebabCaseToTitleCase = (str: string) => {
  return str.replace(/-/g, " ").replace(/\w\S*/g, (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  });
};

// "case-studies/finance" => "finance"
export const removePrimaryTag = (str: string) => {
  return str.replace(/^[\s\w-]*\//, "");
};

// caseStudies => case studies
export const camelCaseToSentenceCase = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").toLowerCase();
};

export const getScrollHeight = (window: Window): number => {
  const { pageYOffset } = window;
  return pageYOffset;
};

export const getReadTimeFromHtml = (html: string, wordsPerMinute?: number): number => {
  const wordCount = html?.split(" ")?.length || 0;
  const readTime = Math.round(wordCount / (wordsPerMinute || 200)) || 1;
  return readTime;
};

// Source: https://stackoverflow.com/questions/5573096/detecting-webp-support
export const isWebpSupported = (): boolean => {
  const cvs = document.createElement("canvas");

  if (!!(cvs.getContext && cvs.getContext("2d"))) {
    return cvs.toDataURL("image/webp").indexOf("data:image/webp") === 0;
  } else {
    return false;
  }
};

export const parseQuery = (queryString?: string): Record<string, string> => {
  if (!queryString) {
    return {};
  }

  const query = {};
  const pairs = (queryString[0] === "?" ? queryString.substring(1) : queryString).split("&");

  for (const pair of pairs) {
    const [name, value] = pair.split("=");
    query[decodeURIComponent(name)] = decodeURIComponent(value || "");
  }

  return query;
};

export const removeFalsyProperties = (obj?: Record<string, unknown>) => {
  if (!obj) {
    return {};
  }

  Object.keys(obj)
    .filter((key) => !obj[key])
    .forEach((key) => delete obj[key]);

  return obj;
};

export const getProductCardDimensions = (
  value: keyof ProductCardDimensions,
  locale: YuLifeLocale,
  dimensions: ProductCardDimensions,
) => {
  return dimensions[locale]?.[value] || "16rem";
};

export const removeDuplicatesFromObjectArrayByProperty = <T>(array: T[], property: string) => {
  const uniqueValues = {};

  return array.filter((item) => {
    if (!uniqueValues[String(item[property])]) {
      uniqueValues[String(item[property])] = true;
      return true;
    }
    return false;
  });
};
